@font-face {
  font-family: 'CodePro-Bold';
  src: url('../public/CodePro-Bold.eot');
  src: url('../public/CodePro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../public/CodePro-Bold.woff2') format('woff2'),
      url('../public/CodePro-Bold.woff') format('woff'),
      url('../public/CodePro-Bold.ttf') format('truetype'),
      url('../public/CodePro-Bold.svg#CodePro-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-family: "Lato", "Helvetica Neue", "Helvetica", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
